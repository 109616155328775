import { createContext, useState } from "react";

declare global {
    interface Window { applicationOptions: IApplicationOptions; }
}

export interface IApplicationOptions {
    WidgetBasePath : string;
}


export class ApplicationOptions implements IApplicationOptions {
    public WidgetBasePath : string;
    constructor() {
        this.WidgetBasePath = window.applicationOptions.WidgetBasePath;
    }
}

export const ApplicationOptionsContext = createContext<IApplicationOptions>(null!);

export const ApplicationOptionsContextProvider = ({children}:any) => {
  const [applicationOptions] = useState<IApplicationOptions>(new ApplicationOptions());
  return (
    <ApplicationOptionsContext.Provider value={applicationOptions}>
      {children}
    </ApplicationOptionsContext.Provider>
  );
}