import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ApplicationOptionsContext } from "./ContextProviders/ApplicationOptionsContextProvider";

export const WidgetWrapper = () => {
    const {memberId} = useParams();
    const [searchParams] = useSearchParams();
    const applicationOptions  = useContext(ApplicationOptionsContext);

    useEffect(() => {
        const cssChunk = document.createElement("link");
        const scriptConfig = document.createElement("script");
        const scriptMain = document.createElement("script");
    
        cssChunk.href = `${applicationOptions.WidgetBasePath}/static/css/main.css`;
        cssChunk.rel = "stylesheet";
        scriptConfig.src = `${applicationOptions.WidgetBasePath}/config.js`;
        scriptMain.src = `${applicationOptions.WidgetBasePath}/static/js/main.js`;
    
        const container = document.getElementById("widget-container") as HTMLElement;
        document.body.appendChild(cssChunk);
        container.appendChild(scriptConfig);
        container.appendChild(scriptMain);
      },[applicationOptions]);

    return (
        <div id="widget-container" member-id={memberId} widget-type={searchParams.has("widgetType")?searchParams.get("widgetType"):"single-consent"}></div>
    )
}