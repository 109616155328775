import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { WidgetWrapper } from './WidgetWrapper';

function App() {

  return (
    <div>
      <h1 className="title" >Wrapping website</h1>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<WidgetWrapper/>}/>
          <Route path="/:memberId" element={<WidgetWrapper/>}/>
        </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;
